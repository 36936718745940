import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

// Basis vertalingen
const messages = {
    'nl': {
        app: {
            portal: "Mijn Omgeving",
            logout: "Uitloggen",
        },
        nav: {
            openMenu: 'Open menu',
            myTickets: 'Mijn tickets',
            myWaitinglist: 'Mijn wachtlijst', 
            personalCredit: 'Persoonlijk tegoed',
            loyaltyCards: 'Voordeelkaarten',
            personalData: 'Persoonlijke gegevens',
            buyTickets: 'Bestel tickets',
            logOut: 'Uitloggen'
        },
        tickets: {
            title: "Mijn tickets",
        },
        history: {
            title: 'Mijn Bestelhistorie',
            noTickets: "Je hebt geen tickets voor evenementen die al geweest zijn.",
            tryAgainOrContact: "Probeer het later nog een keer of neem contact met ons op via",
            or: "of",
        },
        schedule: {
            returntickets: "Retourneren",
            tickets: "Tickets",
        },
        error: {
            fetchingData: "Het is niet gelukt de gegevens op te halen.",
        }
    },
    'en': {
        app: {
            portal: "Customer Portal",
            logout: "Log out",
        },
        nav: {
            openMenu: 'Open menu',
            myTickets: 'My tickets',
            myWaitinglist: 'My waitinglist', 
            personalCredit: 'Personal credit',
            loyaltyCards: 'Loyalty cards',
            personalData: 'Personal data',
            buyTickets: 'Buy tickets',
            logOut: 'Log out'
        },
        tickets: {
            title: "My tickets",
        },
        history: {
            title: 'Order history',
            noTickets: "No tickets for historical events found.",
            tryAgainOrContact: "Try again later or contact us via",
            or: "or",
        },
        schedule: {
            returntickets: "Return",
            tickets: "Tickets",
        },
        error: {
            fetchingData: "Fetching data failed."
        },
    }
};

const i18n = new VueI18n({
    locale: 'nl',
    fallbackLocale: 'en',
    messages,
});

export function mergeAccountTranslations(config) {
    if (!config?.nav) return;

    ['en', 'nl', 'fr', 'de'].forEach(locale => {
        const navTranslations = {};
        
        Object.keys(config.nav).forEach(key => {
            if (config.nav[key]?.[locale]) {
                navTranslations[key] = config.nav[key][locale];
            }
        });

        if (Object.keys(navTranslations).length > 0) {
            i18n.mergeLocaleMessage(locale, {
                nav: navTranslations
            });
        }
    });
}

export default i18n;