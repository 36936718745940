<template>
  <div v-if="!$auth.loading">
    <div id="app" class="flex flex-col min-h-screen" :class="config.class">
      <header>
        <nav class="w-full py-4 bg-secondary shadow hidden sm:block">
            <div class="w-full flex justify-end">
                <ul class="flex items-center justify-between font-bold text-sm text-white uppercase no-underline">
                    <li class="px-4" v-if="config.salesUrl">
                        <a :target="config.urlTarget" title="Bestel tickets" :href="config.salesUrl">
                          <font-awesome-icon icon="calendar-alt" size="2x" class="block m-auto mt-1"/>
                        </a>
                    </li>
                    <li class="px-4" v-if="config.pluginUrl">
                      <Basket/>
                    </li>
                    <template v-if="$auth.isAuthenticated">
                      <li class="px-4" data-private>
                        <router-link v-if="contactLabel && config.loyalty.active"
                          to="/loyalty"
                          class="hover:text-gray-200 hover:underline px-2">
                          <span class="inline-flex items-center rounded-md text-primary px-2 py-1 text-xs font-medium bg-white ring-1 ring-inset ring-gray-500/10">
                            {{ contactLabel }}
                          </span>
                        </router-link>
                        <span v-else-if="contactLabel" class="inline-flex items-center rounded-md text-primary px-2 py-1 text-xs font-medium bg-white ring-1 ring-inset ring-gray-500/10">
                            {{ contactLabel }}
                        </span>
                        
                        {{ fullName }}
                      </li>
                      <li class="px-4">
                        <router-link class="hover:text-gray-200 hover:underline px-4" to="/logout">{{ $t('app.logout') }}</router-link>
                      </li>
                    </template>
                    <li v-else>
                        <a class="hover:text-gray-200 hover:underline px-4" href="/login">{{ $t('app.portal') }}</a>
                    </li>
                </ul>
            </div>
        </nav>

        <div class="w-full lg:w-5/6 xl:w-3/4 2xl:w-1/2 container mx-auto hidden sm:block">
          <div class="flex justify-between py-12">
              <div>
                  <p class="font-bold text-primary hover:text-gray-700 text-5xl">
                      {{ readConfig("title") }}
                  </p>
                  <p class="text-lg text-secondary" v-html="readConfig('subtitle')">
                  </p>
              </div>
              <div v-if="config.image">
                  <a v-if="config.salesUrl" :href="config.salesUrl" target="_blank" rel="noopener noreferrer">
                      <img :src="config.image" class="max-w-lg" style="max-width: 400px; max-height: 150px;" />
                  </a>
                  <img v-else :src="config.image" class="max-w-lg" style="max-width: 400px; max-height: 150px;" />
              </div>
          </div>
        </div>
        <NavBar/>
      </header>

      <main class="flex-grow">
        <router-view/>
      </main>

      <footer class="w-full border-t bg-white bg-gray-100 mt-12">
          <div class="w-full container mx-auto flex flex-col items-center">
              <div class="flex flex-col md:flex-row text-center md:text-left md:justify-between py-6">
                  <a v-for="link of config.footer" v-bind:key="link.url" :href="link.url" :target="config.urlTarget" class="px-3">
                    {{ link.name[lang] }}
                  </a>
              </div>
              <div class="pb-6">
                <a href="https://www.linkedin.com/company/direct-control-nl" target="_blank">
                  Powered by Direct Control
                </a>
              </div>
          </div>
      </footer>
    </div>
  </div>
  <div v-else class="flex justify-center items-center h-screen">
    <font-awesome-icon icon="spinner" spin="spin" size="3x" class="text-primary"/>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n'
import { useFavicon } from '@vueuse/core'
import { mapGetters } from 'vuex'
import Basket from '@/components/Basket.vue'
import NavBar from '@/components/NavBar.vue'

export default {
  title: "Mijn Omgeving",
  components: {
    Basket,
    NavBar
  },
  data() {
    return {
    }
  },
  computed: {
      ...mapGetters(['config', 'fullName', 'contactLabel']),
  },
  watch: {
    config: {
      handler() {
        if (this.config.locale && i18n.locale !== this.config.locale) {
          i18n.locale = this.config.locale
        }

        const icon = useFavicon()
        if (this.config.favicon) {
          icon.value = this.config.favicon
        }          
      },
      deep: true,
    },
  },    
  async created () {
    this.$store.dispatch('resetSchedule')
    this.$store.dispatch('resetHistory')
    this.$store.dispatch('resetWaitinglist')
    this.$store.dispatch('resetCredit')
    this.$store.dispatch('resetLoyalty')
    this.$store.dispatch('resetContactInfo')
    this.$store.dispatch('resetOptins')
  },
}
</script>

<style lang="scss">
  html { overflow-y: scroll; }
</style>