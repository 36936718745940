import Vue from "vue"
import axios from 'axios'
import LogRocket from 'logrocket'
import store from '../store'
import { mergeAccountTranslations } from '@/plugins/i18n'

let instance

/** Returns the current instance of the SDK */
export const getInstance = () => instance

/** Creates an instance of the Auth SDK. If one has already been created, it returns that instance */
export const useAuth = () => {
    if (instance) return instance

    instance = new Vue({
      data() {
        return {
          loading: true,
          isAuthenticated: false,
          user: null,
          contactinfo: null,
          error: null,
        }
      },
      methods: {
        logoutFrontend() {
          this.isAuthenticated = false
          this.user = null
          this.contactinfo = null
          store.dispatch('setAuthInfo', {
            isAuthenticated: false,
            user: null,
            contactinfo: {
              lastname: "",
              middlename: "",
              firstname: "",
              relationtypes: [],
            }
          })
        },        
        async logout() {
           this.logoutFrontend()
           await axios.get('/auth/logout')
        }
      },
      async created() {
        try {
          const response = await axios('/api/config')
          this.isAuthenticated = (response && response.data && response.data.contactid) ? true : false
          this.user = (response && response.data && response.data.contactid) ? response.data.contactid : null
          this.contactinfo = (response && response.data && response.data.contactinfo) ? response.data.contactinfo : {
            lastname: "",
            middlename: "",
            firstname: "",
            relationtypes: [],
          }

          // Store auth info in Vuex
          store.dispatch('setAuthInfo', {
            isAuthenticated: this.isAuthenticated,
            user: this.user,
            contactinfo: this.contactinfo
          })

          if (this.isAuthenticated){
            await store.dispatch('setAccountConfig', response.data.config);
            
            mergeAccountTranslations(response.data.config)

            LogRocket.identify(this.user, {
              name: this.contactinfo.lastname,
            })
          }
        }
        catch (error) {
          this.logoutFrontend()
        }
        finally {
          this.loading = false
        }
      }
    })
  
    return instance
}
  
  // Create a simple Vue plugin to expose the wrapper object throughout the application
  export const AuthPlugin = {
    install(Vue, options) {
      Vue.prototype.$auth = useAuth(options)
    }
  }